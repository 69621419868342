import DomainContextParams from '@/utils/types/DomainContextParams';
import { CATEGORY_BASE_FRAGMENT } from '@/graphql/_Fragments/Category/Base';

export const EXHIBITOR_MAP_FRAGMENT = `
  fragment exhibitorMapFragment on Exhibitor {
    uid
    id
    schemaCode
    __typename
    name
    featured
    description
    editionMappings {
      editionExhibitor {
        uid
        mainExhibitor
     }
    }
    categoriesInContext(context: "${DomainContextParams.company}") {
      ...categoryBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isVisited(myUid: "%authUser%")
  }
  ${CATEGORY_BASE_FRAGMENT}
`;
